@import './assets/fonts/roboto';
@import './assets/fonts/material-icons';
@import './themes/dark/style.scss';
@import '~video.js/dist/video-js.css';

@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~moment-timezone-picker/assets/moment-timezone-picker.theme";

/*
  ng-select themeing
*/
// input field
.ng-select .ng-input>input {
  color: white !important;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  margin-bottom: 10px;
  padding-left: 10px;
}
// selected value
.ng-select .ng-select-container {
  color: white !important;
}
// placeholder tweaks
.ng-select .ng-select-container .ng-placeholder:focus {
  color: $icetana-primary !important;
}
.ng-select .ng-select-container .ng-placeholder:not(:focus) {
  color: rgba(255, 255, 255, 0.7);
  background-color: $icetana-black !important;
}
// keep placeholder in border area
.ng-select .ng-has-value .ng-placeholder, 
.ng-select.ng-select-opened .ng-placeholder, 
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  margin-top: -0.5em;
}
// value container tweaks
.ng-select .ng-select-container .ng-value-container {
  padding-top: 0.84375em;
}
// Caret and clear cross
.ng-select .ng-arrow-wrapper .ng-arrow:focus {
  color: $icetana-primary !important; 
}
.ng-select .ng-arrow-wrapper .ng-arrow:not(:focus), .ng-clear-wrapper .ng-clear {
  color: rgba(255,255,255,0.7); 
}
// Borders
.ng-select .ng-select-container.ng-appearance-outline:after {
  border: thin solid rgba(255,255,255,0.3);
}
.ng-select .ng-select-container.ng-appearance-outline:hover:after {
  border: thin solid white;
}
.ng-select .ng-select-container.ng-appearance-outline:focus:after {
  border: thin solid $icetana-primary;
}
// the dropdown panel
.ng-dropdown-panel {
  background-color: #424242; // derived from @angular/material/card/_card-theme.scss
  margin-top: -18px; // when bound to body (appendTo) the panel sits too far down
  .ng-optgroup {
    color: rgba(255, 255, 255, 0.7) !important;
  }
  .ng-option-child {
    color: white !important;
  }
  .ng-option-marked {
    background-color: rgba(255, 255, 255, 0.04) !important;
    color: white !important;
  }
  .ng-option.ng-option-selected {
    background-color: rgba(255, 255, 255, 0.04) !important;
  }
  .ng-option.ng-option-disabled {
    color: white !important;
  }
}

/* setting the scrollbar globally */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  overflow-y: scroll;
  background: $dark-bg-lighter-20;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: $icetana-gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $icetana-gray-dark;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
  background: $icetana-active;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background-color: $icetana-gray-darkest;
}

// custom divider useable throughout app
hr {
  width: 100%;
  border: none;
  height: 1px;
  display: block;
  margin: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(255, 255, 255, 0.12);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  //background-color: $icetana-gray-lightest;
  background-color: $icetana-gray-darkest;
}

.footer-button {
  display: flex;
  flex-direction: row-reverse;

  button {
    margin: 1rem;
    align-items: flex-end;
    width: 20rem;
  }
}

.footer {
  color: white;
  padding: $lg;
}

main {
  margin: 0;
  padding: 0;
  height: 100%;
}

/**
Inspired by the Bootstrap 4 Utilities
https://getbootstrap.com/docs/4.5/utilities/borders/
*/

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: $md;
  padding-left: $md;
  margin-right: auto;
  margin-left: auto;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: $sm !important;
}
.mt-2 {
  margin-top: $md !important;
}
.mt-3 {
  margin-top: $lg !important;
}
.mt-4 {
  margin-top: $xl !important;
}
.mt-5 {
  margin-top: $xxl !important;
}

.pt-0 {
  margin-top: 0 !important;
}
.pt-1 {
  margin-top: $sm !important;
}
.pt-2 {
  margin-top: $md !important;
}
.pt-3 {
  margin-top: $lg !important;
}
.pt-4 {
  margin-top: $xl !important;
}
.pt-5 {
  margin-top: $xxl !important;
}

.text-center {
  text-align: center !important;
}

/**
  Material SnackBar
*/
.snackbar-success-background {
  background-color: $icetana-accent1;
}
.snackbar-error-background {
  background-color: $icetana-accent3;
}
.snackbar-info-background {
  background-color: $icetana-navy-light;
}

/**
DataTable
*/
.data-table-view {
  box-shadow: none !important;
}

/**
icon Colors
*/
.started-icon {
  color: $icetana-accent1;
}

.failed-icon {
  color: $icetana-flag;
}

.alert-icon {
  color: $icetana-accent2;
}

/**
  Menu Class Overrides
*/

.cdk-overlay-container .mat-menu-panel {
  min-height : auto !important;
}

/**
  Media Classes
*/

// 576px => 36rem
@media (min-width: 36rem) {
  // 540px => 36rem
  .container,
  .container-sm {
    max-width: 33.75rem;
  }
}

// 768px => 48rem
@media (min-width: 48rem) {
  // 720px => 45rem
  .container,
  .container-md,
  .container-sm {
    max-width: 45rem;
  }
}

// 992px => 62rem
@media (min-width: 62rem) {
  // 960px => 60rem
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 60rem;
  }
}

// 1200px => 75rem
@media (min-width: 75rem) {
  // 1140px => 71.25rem
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 71.25rem;
  }
}

// container-fluid will unset any width restriction
.container-fluid {
  max-width: unset;
}
