// Default Colors
$icetana-primary: #245fad; // derived from branding
$icetana-active: #64b5f6;
$icetana-secondary-mint: #94f29a; // derived from branding
$icetana-secondary-blue: #043fa8; // derived from branding
$icetana-secondary-blue-dark: #002069; // derived from branding

$icetana-accent1: #28a745;
$icetana-accent2: #ffc000;
$icetana-accent3: #d3301a;
$icetana-flag: #f58623;
$icetana-flag2: #bd10e0;
$icetana-black-base: #000000;
$icetana-black: #1e2527;
$icetana-gray-darkest: #283133;
$icetana-gray-darker: #40494a;
$icetana-gray-dark: #828b8e; // derived from branding
$icetana-gray: #9da0a0;
$icetana-gray-light: #c8ced0;
$icetana-gray-lighter: #dee0e2; // derived from branding
$icetana-gray-lightest: #f3fbfd;
$icetana-white: #ffffff;
$icetana-navy-light: #64b5f6;
$icetana-navy: #2196f3;
$icetana-navy-dark: #1976d2;

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);
